.App {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: gray;
}

.redBox {
  display: flex;
  flex: 1;
  background-color: red;
  width: 100%;
}

.greenBox {
  display: flex;
  flex: 1;
  background-color: green;
  width: 100%;
}

.yellowBox {
  display: flex;
  flex: 1;
  background-color: yellow;
  width: 100%;
}

.purpleBox {
  display: flex;
  flex: 1;
  background-color: purple;
  width: 100%;
}

.binsDates {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: orange;
  padding: 5px;
  font-size: small;
}

.mainMessage {
  position: absolute;
  color: white;
  background-color: black;
  padding: 10px;
}

.redDot {
  height: 15px;
  width: 15px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.greenDot {
  height: 15px;
  width: 15px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
}

.yellowDot {
  height: 15px;
  width: 15px;
  background-color: yellow;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
}

.purpleDot {
  height: 15px;
  width: 15px;
  background-color: purple;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
}